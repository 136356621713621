@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
html{
  background-color: #204363;
  /* background-image: linear-gradient(160deg, #35322c 0%, #0c9a88 100%); */
  /* background-image: url("http://localhost:3000/bg.png"); */
  /* background- */
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: blue; */

  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
